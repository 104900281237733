import { IAccountVM } from '../interfaces/views/IAccountVM';
import { createContext, FC, useContext, PropsWithChildren } from 'react';
import { useGetAccountsQuery } from '../services/AccountsQueryService';
import { EAccountStatus } from '../interfaces/enums/EAccountStatus';
import { EAccountType } from '../interfaces/enums/EAccountType';

export interface IAccountsContext {
    data?: IAccountVM[];
    isAccountsDataLoading: boolean;
    isAccountsDataFetchingError: boolean;
}

export const AccountsContext = createContext<IAccountsContext>({} as IAccountsContext);

interface IProps {}

export const AccountsProvider: FC<PropsWithChildren<IProps>> = ({ children }) => {
    const {
        data: fetchedAccountsData,
        isLoading: isAccountsDataLoading,
        isError: isAccountsDataFetchingError
    } = useGetAccountsQuery();

    const accountsState: IAccountsContext = {
        data: fetchedAccountsData || [
            {
                id: '1',
                companyName: 'Hanai Global',
                status: EAccountStatus.INITIATED,
                type: EAccountType.RESELLER,
                nuliaLicenses: 33,
                officeLicenses: 24
            },
            {
                id: '2',
                companyName: 'Nulia',
                status: EAccountStatus.GOOD,
                type: EAccountType.CUSTOMER,
                nuliaLicenses: 21,
                officeLicenses: 55
            }
        ],
        isAccountsDataLoading: isAccountsDataLoading,
        isAccountsDataFetchingError
    };

    return <AccountsContext.Provider value={accountsState}>{children}</AccountsContext.Provider>;
};

export const useAccountsStateValue: () => IAccountsContext = () => useContext(AccountsContext);
