import { Typography } from '@mui/material';
import { styled } from '@mui/system';

export const RootBox = styled('div')(({ theme }) => ({
    borderRadius: 4,
    boxShadow: '0 0.5px 1.5px 0 rgba(0, 0, 0, 0.08), 0 1px 4px 0.5px rgba(0, 0, 0, 0.08)',
    backgroundColor: '#ffffff',
    padding: '6px 24px 24px 24px',
    marginBottom: 24,
    gap: '16px',
    overflow: 'hidden'
}));

export const TitleTypography = styled(Typography)({});

export const ContentBox = styled('div')({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    width: '100%',
    gap: '16px'
});

export const HeaderBox = styled('div')({
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center'
});

export const ItemBox = styled('div')({
    display: 'grid',
    gridTemplateColumns: '300px 50px',
    width: '100%',
    alignItems: 'center',
    gap: '12px'
});

export const ItemLabelTypography = styled(Typography)({
    '&::after': {
        content: '":"'
    }
});

export const ItemValueTypography = styled(Typography)({
    textAlign: 'right'
});

export const ContentColumn = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '12px'
});
