import { useTheme } from '@mui/system';
import { useNavigationStateValue } from '../../../contexts/NavigationContext';
import { VERSION } from '../../../utils/constants';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createUrl } from '../../../utils/createUrl';
import fetchImageErrorCallback from '../../../utils/fetchImageErrorCallback';
import { FC, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PartnerPortalNavigationLinks from '../PartnerPortalNavigationLinks';
import { Links, Logo, NavigationBox, NavigationLogoImg, PartnerNavigationBox, StyledX, Version } from '../Style';
import { routes } from '../../../pages/routes';
import { ERoute } from '../../../interfaces/enums/ERoute';
import { Award, BarChart2, Briefcase, Home, Settings, Star } from 'react-feather';
import OutcomesIcon from '../../../assets/icons/OutcomesIcon';
import HowToNavigationIcon from '../../../assets/icons/HowToNavigationIcon';
import NavigationLinkItem from '../navigation-link-item/NavigationLinkItem';
import { isUserAllowedToAccess, routeRules } from '../../../pages/routeRules';
import { useUserAuthStateValue } from '../../../contexts/UserAuthContext';

interface IProps {
    isAlwaysOpen?: boolean;
}

const navigationLinks = [
    {
        id: 'navigation-home-button',
        title: 'Home',
        icon: <Home key='home-icon' />,
        paths: [routes.HOME, '/'],
        routeRules: [ERoute.HOME],
        primaryPath: routes.HOME
    },
    {
        id: 'navigation-skills-button',
        title: 'Skills',
        icon: <Star key='skills-icon' />,
        paths: [routes.SKILLS, routes.SKILL],
        routeRules: [ERoute.SKILLS, ERoute.SKILL]
    },
    {
        id: 'navigation-outcomes-button',
        title: 'Outcomes',
        icon: <OutcomesIcon key='outcomes-icon' />,
        paths: [routes.OUTCOME, routes.ADMIN_OUTCOMES, routes.USER_OUTCOMES, routes.HISTORY_OUTCOMES],
        routeRules: [ERoute.USER_OUTCOMES, ERoute.ADMIN_OUTCOMES, ERoute.OUTCOMES_HISTORY, ERoute.OUTCOMES]
    },
    {
        id: 'navigation-showcase-button',
        title: 'Showcase',
        icon: <Award key='award-icon' />,
        paths: [routes.SHOWCASE_HOME],
        routeRules: [ERoute.SHOWCASE_HOME]
    },
    {
        id: 'navigation-how-tos-button',
        title: "How To's",
        icon: <HowToNavigationIcon />,
        paths: [routes.HOW_TOS_HOMEPAGE],
        routeRules: [ERoute.HOW_TOS_HOMEPAGE]
    },
    {
        id: 'navigation-insights-button',
        title: 'Insights',
        icon: <BarChart2 key='chart-icon' />,
        routeRules: [
            ERoute.INSIGHTS_PERSONAL_SKILLS,
            ERoute.INSIGHTS_PERSONAL_OUTCOMES,
            ERoute.INSIGHTS_PERSONAL_ENGAGEMENT,
            ERoute.INSIGHTS_PERSONAL_BADGES,
            ERoute.INSIGHTS_ORGANIZATION_USERS,
            ERoute.INSIGHTS_ORGANIZATION_OUTCOMES,
            ERoute.INSIGHTS_ORGANIZATION_ENGAGEMENT,
            ERoute.INSIGHTS_ORGANIZATION_BADGES,
            ERoute.INSIGHTS_ORGANIZATION_ASSESSMENT,
            ERoute.INSIGHTS_ORGANIZATION_USER_BADGES,
            ERoute.INSIGHTS_ORGANIZATION_USER_ENGAGEMENT,
            ERoute.INSIGHTS_ORGANIZATION_USER_OUTCOMES,
            ERoute.INSIGHTS_ORGANIZATION_USER_SKILLS
        ],
        paths: [
            routes.INSIGHTS_PERSONAL_SKILLS,
            routes.INSIGHTS_PERSONAL_OUTCOMES,
            routes.INSIGHTS_PERSONAL_ENGAGEMENT,
            routes.INSIGHTS_PERSONAL_BADGES,
            routes.INSIGHTS_ORGANIZATIONAL_USERS,
            routes.INSIGHTS_ORGANIZATIONAL_OUTCOMES,
            routes.INSIGHTS_ORGANIZATIONAL_ENGAGEMENT,
            routes.INSIGHTS_ORGANIZATIONAL_BADGES,
            routes.INSIGHTS_ORGANIZATIONAL_ASSESSMENT,
            routes.INSIGHTS_ORGANIZATIONAL_USER_BADGES,
            routes.INSIGHTS_ORGANIZATIONAL_USER_ENGAGEMENT,
            routes.INSIGHTS_ORGANIZATIONAL_USER_OUTCOMES,
            routes.INSIGHTS_ORGANIZATIONAL_USER_SKILLS,
            routes.INSIGHTS_ORGANIZATIONAL_OUTCOMES_DETAILS,
            routes.INSIGHTS_PERSONAL,
            routes.INSIGHTS_ORGANIZATIONAL
        ]
    },
    {
        id: 'navigation-settings-button',
        title: 'Settings',
        icon: <Settings key='settings-icon' />,
        routeRules: [ERoute.SETTINGS_PERSONAL, ERoute.SETTINGS_ORGANIZATIONAL],
        paths: [routes.SETTINGS_PERSONAL, routes.SETTINGS_ORGANIZATIONAL, routes.SETTINGS]
    },
    {
        id: 'navigation-admin-button',
        title: 'Admin',
        icon: <Briefcase key='admin-icon' />,
        paths: [routes.ADMIN_LICENSES, routes.ADMIN_ROLES, routes.ADMIN_HISTORY, routes.ADMIN_HISTORY_DETAILS],
        routeRules: [ERoute.ADMIN_LICENSES, ERoute.ADMIN_ROLES, ERoute.ADMIN_HISTORY]
    }
    // {
    //     id: 'partner-portal-button',
    //     title: 'Partner',
    //     icon: <Server key='server-icon' />,
    //     routeRules: [ERoute.PARTNER_ACCOUNTS],
    //     paths: [routes.PARTNER_ACCOUNTS]
    // }
];

const NavigationLinks: FC<IProps> = ({ isAlwaysOpen = false }) => {
    const { showNav, setShowNav } = useNavigationStateValue();
    const theme = useTheme();
    const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));
    const { pathname } = useLocation();
    const { currentUserData } = useUserAuthStateValue();
    const [isPartnerPortalMode, setPartnerPortalMode] = useState<boolean>(false);

    useEffect(() => {
        setPartnerPortalMode(pathname.startsWith('/partner'));
    }, [pathname]);

    const isOpen = useMemo(() => {
        return showNav || isAlwaysOpen;
    }, [showNav, isAlwaysOpen]);

    const contentMemo = useMemo(() => {
        if (isPartnerPortalMode && isOpen) {
            return (
                <PartnerNavigationBox>
                    <Logo>
                        {!isLgUp && (
                            <StyledX
                                color='#FFF'
                                onClick={() => {
                                    setShowNav(!showNav);
                                }}
                            />
                        )}
                        {/* <NavigationLogoImg
                            alt="logo"
                            src={createUrl(`nulia-cdn://nwcontent/images/logos/nulia_logo_small.svg`)?.generatedUrl}
                            onError={fetchImageErrorCallback}
                            color="#FFF"
                        /> */}
                    </Logo>
                    <Links>
                        <PartnerPortalNavigationLinks />
                    </Links>
                    <Version color='#FFF'>{VERSION}</Version>
                </PartnerNavigationBox>
            );
        }
        if (isOpen)
            return (
                <NavigationBox>
                    <Logo>
                        {!isLgUp && (
                            <StyledX
                                onClick={() => {
                                    setShowNav(!showNav);
                                }}
                            />
                        )}
                        <NavigationLogoImg
                            alt='logo'
                            src={createUrl(`nulia-cdn://nwcontent/images/logos/nulia_logo_small.svg`)?.generatedUrl}
                            onError={fetchImageErrorCallback}
                        />
                    </Logo>
                    <Links>
                        <div>
                            {navigationLinks
                                .filter((link) => {
                                    let isAllowed = false;
                                    link.routeRules?.forEach((routeRule: ERoute) => {
                                        if (
                                            routeRules[routeRule].rules.find(() => {
                                                return currentUserData?.roles
                                                    ? isUserAllowedToAccess(
                                                          routeRule,
                                                          currentUserData?.roles,
                                                          currentUserData?.permissions
                                                      )
                                                    : false;
                                            })
                                        )
                                            isAllowed = true;
                                    });
                                    return isAllowed;
                                })
                                .map((link) => {
                                    const primaryRouteRule: ERoute | undefined = link.routeRules?.find((routeRule) => {
                                        if (
                                            routeRule &&
                                            currentUserData &&
                                            isUserAllowedToAccess(
                                                routeRule,
                                                currentUserData.roles,
                                                currentUserData.permissions
                                            )
                                        ) {
                                            return true;
                                        }
                                        return false;
                                    });
                                    return (
                                        <NavigationLinkItem
                                            key={link.id}
                                            title={link.title}
                                            icon={link.icon}
                                            primaryPath={primaryRouteRule ? routeRules[primaryRouteRule].route : ''}
                                            paths={link.paths}
                                        ></NavigationLinkItem>
                                    );
                                })}
                        </div>
                    </Links>
                    <Version>{VERSION}</Version>
                </NavigationBox>
            );
        return <></>;
    }, [isPartnerPortalMode, isOpen]);

    return <>{contentMemo}</>;
};

export default NavigationLinks;
